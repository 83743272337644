<template>
  <section>
    <b-row>
      <b-col cols="12">

        <daas-form />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import DaasForm from '@/components/DaasForm.vue'

export default {
  components: {
    BRow,
    BCol,
    DaasForm,
  },
}
</script>

  <style>

  </style>
