<template>
  <b-form @submit.prevent>
    <div class="w-100 text-center">
      <b-row>
        <b-col
          v-if="showHeader"
          cols="12"
        >
          <h2 class="mb-1">
            Request A Dispatch 🚀
          </h2>
          <p class="mb-3">
            1-Click to the nearest delivery service companies, riders,
            drivers to handle your delivery request!
          </p>
        </b-col>

        <b-col
          cols="12"
          class="mb-1 text-left"
        >
          <!-- dismissible Alert -->
          <b-alert
            v-height-fade.appear
            variant="danger"
            dismissible
            fade
            :show="showDismissibleAlert"
            class="mb-0"
            @dismissed="showDismissibleAlert = false"
          >
            <h4 class="alert-heading">
              {{ error_head }}
            </h4>
            <div class="alert-body">
              <span>{{ error_body }}</span>
              <div v-if="hasError">
                <div
                  v-for="(item, index) in errors.data"
                  :key="index"
                >
                  * <small>{{ item.toString().replace('dispatch_object.', '').replace('_', ' ').replace(/\./g, ' ') }}</small>
                </div>
              </div>
            </div>
          </b-alert>
        </b-col>
        <b-col cols="12">
          <b-row>
            <!--CUSTOMER DETAILS-->
            <b-col
              md="4"
              sm="12"
              class="text-left"
            >
              <b-card>
                <h4>CUSTOMER DETAILS</h4>
                <div>
                  <b-row>
                    <!--Search Customers-->
                    <b-col
                      v-if="!isNewCustomer"
                      cols="12"
                    >
                      <b-form-group
                        label="Search"
                        label-for=""
                      >
                        <vue-autosuggest
                          ref="autocomplete"
                          v-model="query"
                          :suggestions="suggestions"
                          :input-props="inputProps"
                          :section-configs="sectionConfigs"
                          :render-suggestion="renderSuggestion"
                          :get-suggestion-value="getSuggestionValue"
                          @input="fetchResults"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- New or Old user -->
                    <b-col md="6">
                      <b-form-group
                        label="New Customer"
                        label-for="new-customer"
                      >
                        <b-form-checkbox
                          v-model="isNewCustomer"
                          class="custom-control-primary"
                          name="new-customer"
                          switch
                          @change="resetCustomer"
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>

                    <!-- mobile -->
                    <b-col md="6">
                      <b-form-group
                        label="Mobile*"
                        label-for="vi-mobile"
                      >
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="SmartphoneIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="vi-mobile"
                            v-model="daas.params.phone"
                            type="text"
                            placeholder="Mobile"
                            :readonly="!isNewCustomer"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>

                    <!-- name -->
                    <b-col cols="12">
                      <b-form-group
                        label="Name*"
                        label-for="vi-name"
                      >
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="UserIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="vi-name"
                            v-model="daas.params.name"
                            type="text"
                            placeholder="Name"
                            :readonly="!isNewCustomer"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>

                    <!-- email -->
                    <b-col cols="12">
                      <b-form-group
                        label="Email"
                        label-for="vi-email"
                      >
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="MailIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="vi-email"
                            v-model="daas.params.email"
                            type="email"
                            placeholder="Email"
                            :readonly="!isNewCustomer"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12">
                      <hr>
                    </b-col>

                    <!-- Pickup address -->
                    <b-col cols="12">
                      <b-form-group
                        label="Pickup Address*"
                        label-for="vi-pickup-address"
                      >
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="MapPinIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="vi-pickup-address"
                            v-model="
                              daas.dispatch_object.pickup.address
                            "
                            placeholder="Pickup Address"
                            required
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </b-card>
            </b-col>

            <!--DISPATCH DETAILS-->
            <b-col
              md="8"
              sm="12"
              class="text-left"
            >
              <b-card>
                <h4>DROPOFF DETAILS</h4>
                <div>
                  <b-row>
                    <!-- <b-col cols="12">
                          <h6>DropOffs</h6>
                          <hr />
                        </b-col> -->

                    <!-- isInstant -->
                    <b-col md="2">
                      <b-form-group
                        label="Instant Dispatch"
                        label-for="is-instant"
                      >
                        <b-form-checkbox
                          v-model="isInstant"
                          class="custom-control-primary"
                          name="is-instant"
                          switch
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>

                    <!-- scheduled for -->
                    <b-col
                      v-if="!isInstant"
                      md="4"
                    >
                      <b-form-group>
                        <label>Date & TIme</label>
                        <flat-pickr
                          v-model="daas.dispatch_object.scheduled_for"
                          class="form-control"
                          :config="{
                            enableTime: true,
                            dateFormat: 'Y-m-d H:i',
                          }"
                          placeholder="Scheduled For"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col cols="12">
                      <!-- Drop Offs -->
                      <div>
                        <div
                          ref="form"
                          :style="{ height: trHeight }"
                          class="repeater-form"
                        >
                          <!-- Row Loop -->
                          <b-row
                            v-for="(item, index) in daas.dispatch_object
                              .dropoffs"
                            :id="item.id"
                            :key="item.id"
                            ref="row"
                          >
                            <!-- Address -->
                            <b-col md="10">
                              <b-form-group
                                label="Address*"
                                label-for="item-name"
                              >
                                <b-form-input
                                  id="item-name"
                                  v-model="
                                    daas.dispatch_object.dropoffs[index]
                                      .address
                                  "
                                  type="text"
                                  placeholder="Address"
                                  required
                                />
                                <!-- <vue-google-autocomplete
                                          :ref="'address' + index"
                                          :id="'map' + index"
                                          classname="form-control"
                                          placeholder="Please type pickup address"
                                          v-on:placechanged="
                                            getReceiverAddressData
                                          "
                                          country="ng"
                                          v-model="
                                            daas.dispatch_object.dropoffs[index]
                                              .address
                                          "
                                        >
                                        </vue-google-autocomplete> -->
                              </b-form-group>
                            </b-col>

                            <!-- Longitude -->
                            <!-- <b-col md="2" v-if="1 == 1">
                                      <b-form-group
                                        label="Longitude"
                                        label-for="longitude"
                                      >
                                        <b-form-input
                                          id="longitude"
                                          type="text"
                                          placeholder="0.00"
                                          v-model="
                                            daas.dispatch_object.dropoffs[index]
                                              .longitude
                                          "
                                          readonly
                                        />
                                      </b-form-group>
                                    </b-col> -->

                            <!-- Latitude -->
                            <!-- <b-col md="2" v-if="1 == 1">
                                      <b-form-group
                                        label="Latitude"
                                        label-for="latitude"
                                      >
                                        <b-form-input
                                          id="latitude"
                                          type="text"
                                          placeholder="0.00"
                                          v-model="
                                            daas.dispatch_object.dropoffs[index]
                                              .latitude
                                          "
                                          readonly
                                        />
                                      </b-form-group>
                                    </b-col> -->

                            <!-- receiver's name -->
                            <b-col
                              md="6"
                              sm="12"
                            >
                              <b-form-group
                                label="Receiver's Name*"
                                label-for="vi-first-name"
                              >
                                <b-input-group
                                  class="input-group-merge"
                                >
                                  <b-input-group-prepend is-text>
                                    <feather-icon icon="UserIcon" />
                                  </b-input-group-prepend>
                                  <b-form-input
                                    id="vi-first-name"
                                    v-model="
                                      daas.dispatch_object.dropoffs[
                                        index
                                      ].receiver_name
                                    "
                                    placeholder="Receiver's Name"
                                    required
                                  />
                                </b-input-group>
                              </b-form-group>
                            </b-col>

                            <!-- mobile -->
                            <b-col
                              md="4"
                              sm="12"
                            >
                              <b-form-group
                                label="Receiver's Mobile*"
                                label-for="vi-mobile"
                              >
                                <b-input-group
                                  class="input-group-merge"
                                >
                                  <b-input-group-prepend is-text>
                                    <feather-icon
                                      icon="SmartphoneIcon"
                                    />
                                  </b-input-group-prepend>
                                  <b-form-input
                                    id="vi-mobile"
                                    v-model="
                                      daas.dispatch_object.dropoffs[
                                        index
                                      ].receiver_phone
                                    "
                                    type="number"
                                    placeholder="Receiver's Mobile"
                                    required
                                  />
                                </b-input-group>
                              </b-form-group>
                            </b-col>

                            <b-col cols="12">
                              <h6>Packages*</h6>
                            </b-col>

                            <b-col cols="12 mx-md-2">
                              <div>
                                <b-row>
                                  <b-col
                                    v-for="(
                                      pkg, pindex
                                    ) in item.packages"
                                    :id="pkg.id"
                                    :key="pkg.id"
                                    ref="col"
                                    md="8"
                                    class="mb-1"
                                  >
                                    <b-input-group>
                                      <b-input-group-prepend>
                                        <b-button
                                          variant="outline-secondary"
                                        >
                                          Name:
                                        </b-button>
                                      </b-input-group-prepend>
                                      <b-form-input
                                        v-model="
                                          daas.dispatch_object.dropoffs[
                                            index
                                          ].packages[pindex].name
                                        "
                                        placeholder="Package Name or Description"
                                        required
                                      />
                                      <b-input-group-append>
                                        <b-button
                                          variant="outline-danger"
                                          @click="
                                            removePackage(index, pindex)
                                          "
                                        >
                                          <feather-icon
                                            icon="Trash2Icon"
                                          />
                                        </b-button>
                                      </b-input-group-append>
                                    </b-input-group>
                                  </b-col>
                                  <b-col cols="2">
                                    <b-button
                                      v-ripple.400="
                                        'rgba(234, 84, 85, 0.15)'
                                      "
                                      variant="primary"
                                      class="mb-md-0"
                                      @click="repeatPackage(index)"
                                    >
                                      <feather-icon icon="PlusIcon" />
                                    </b-button>
                                  </b-col>
                                </b-row>
                              </div>
                            </b-col>

                            <!-- Remove Button -->
                            <b-col
                              lg="12"
                              md="12"
                              class="mb-50 mt-1"
                            >
                              <b-button
                                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                variant="outline-danger"
                                class="mt-0 mt-md-0"
                                @click="removeItem(index)"
                              >
                                <feather-icon
                                  icon="XIcon"
                                  class="mr-25"
                                />
                                <span>Delete</span>
                              </b-button>
                            </b-col>

                            <b-col cols="12">
                              <hr>
                            </b-col>
                          </b-row>
                        </div>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          @click="repeatAgain"
                        >
                          <feather-icon
                            icon="PlusIcon"
                            class="mr-25"
                          />
                          <span>Add DropOff</span>
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <!--SUBMIT BUTTON-->
          <b-col
            cols="12"
            class="mt-2"
          >
            <b-button
              variant="primary"
              class="mb-1 btn-sm-block"
              type="submit"
              @click="submitDaas"
            >
              Submit
            </b-button>
            <b-button
              variant="danger"
              class="mb-1 btn-sm-block ml-md-2"
              @click="confirmText"
            >
              Cancel
            </b-button>
          </b-col>
        </b-col>
      </b-row>
    </div>
  </b-form>
</template>
<script>
// TODO: Add validation for the form
import {
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BRow,
  BAlert,
  BForm,
} from 'bootstrap-vue'

import { capiUrl, apiUrl } from '@/constants/config'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { heightFade } from '@core/directives/animations'
import store from '@/store'
import { required, email } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BCard,
    BFormGroup,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BCol,
    BRow,
    BButton,
    FeatherIcon,
    BAlert,
    BForm,
  },

  directives: {
    heightFade,
    Ripple,
  },

  mixins: [heightTransition],
  props: {
    showHeader: Boolean,
    customerDaas: Boolean,
  },

  data() {
    return {
      showDismissibleAlert: false,
      error_head: '',
      error_body: '',
      isNewCustomer: true,
      isInstant: true,
      customer: {
        phone: '',
        name: '',
        email: '',
        id: '',
      },
      daas: {
        params: {
          name: '',
          phone: '',
          email: '',
        },
        id: '',
        dispatch_object: {
          pickup: {
            address: '',
            longitude: '1',
            latitude: '2',
          },
          dropoffs: [
            {
              address: '',
              longitude: '3',
              latitude: '4',
              receiver_name: '',
              receiver_phone: '',
              packages: [{ id: 1, name: '' }],
              id: 1,
              nextPackageId: 1,
            },
          ],
          duration: '60',
          scheduled_for: null,
        },
      },

      // autosuggest
      query: '',
      results: [],
      timeout: null,
      selected: null,
      debounceMilliseconds: 250,
      customersUrl: `${capiUrl}search`,
      inputProps: {
        id: 'autosuggest__input_ajax',
        placeholder: 'Name, Phone or Email',
        class: 'form-control',
        name: 'ajax',
      },
      suggestions: [],
      sectionConfigs: {
        customers: {
          limit: 6,
          // label: "Customers",
          onSelected: selected => {
            this.selected = selected.item
            this.customer = this.selected
          },
        },
      },
      address: '',
      hasError: false,
      errors: {
        message: '',
        data: [],
      },
    }
  },

  mounted() {
    this.initTrHeight()
    // this.showHeader = true
  },

  created() {
    window.addEventListener('resize', this.initTrHeight)
    this.getDaas()
  },

  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },

  methods: {
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },

    parseError(response) {
      if (response.status === 422) {
        this.errors.message = response.data.message
        this.errors.data = response.data.errors

        return response.data.message
      } if (response.status === 500) {
        this.errors.message = response.data.message
        this.errors.data = []
        return response.status
      }
      return 'this is an error'
    },

    // confirm text
    confirmText() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to return to this dispatch request, after you cancel!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, cancel!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          setTimeout(() => {
            this.$router.replace({ path: '/cancelled' })
          }, 500)
        }
      })
    },

    getDaas() {
      this.show = true
      try {
        axios
          .get(`${apiUrl}daas/${this.$route.params.ref}`, {
            headers: {
              'content-type': 'application/json',
            },
          })
          .then(response => {
            const daas = response.data.data

            if (daas.status !== 'LINK') {
              setTimeout(() => {
                this.$router.replace({
                  path: `/invoice/${this.$route.params.ref}`,
                })
              }, 500)
            }

            this.daas.id = daas.id
            this.daas.params.name = daas.params.name ?? this.customer.name
            this.daas.params.phone = daas.params.phone ?? this.customer.phone
            this.daas.params.email = daas.params.email ?? this.customer.email
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.show = false
          })
      } catch (e) {
        console.log(e)
      }
    },

    submitDaas(event) {
      this.hasError = false
      this.showOverlay = true
      event.preventDefault()

      // remove temporary parameters

      Object.keys(this.daas.dispatch_object.dropoffs).forEach(key => {
        const obj = this.daas.dispatch_object.dropoffs[key]
        delete obj.id
        delete obj.nextPackageId

        Object.keys(obj.packages).forEach(packageKey => {
          const o = obj.packages[packageKey]
          delete o.id
        })
      })

      //   Set status and duration for customer filled forms
      this.daas.status = 'REQUEST'
      this.daas.dispatch_object.duration = 60

      const url = this.customerDaas ? `${apiUrl}daas/${this.daas.id}` : `${apiUrl}store-customer-daas`
      const method = this.customerDaas ? 'PUT' : 'POST'
      const Authorization = this.customerDaas ? '' : `Bearer ${store.state.user.currentUser.token}`

      const path = this.customerDaas ? `/invoice/${this.$route.params.ref}` : '/daas'

      // post data
      axios({
        url,
        data: this.daas,
        method,
        headers: {
          'content-type': 'application/json',
          Authorization,
        },
      })
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Success: ${response.data.message}`,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          setTimeout(() => {
            this.$router.replace({
              path,
            })
          }, 500)
        })
        .catch(error => {
          this.hasError = true

          this.showDismissibleAlert = true
          this.error_head = `Error: ${error.response.statusText}`
          this.error_body = error.response.data.message

          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Error: ${this.parseError(error.response)}`, // + error.response.statusText ?? error.message,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.showOverlay = false
        })
    },

    repeatAgain() {
      this.daas.dispatch_object.dropoffs.push({
        id: (this.nextTodoId += 1),
        packages: [{ id: 1, name: '' }],
        nextPackageId: 1,
        longitude: '3',
        latitude: '4',
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },

    removeItem(index) {
      this.daas.dispatch_object.dropoffs.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },

    resetCustomer() {
      this.customer = {
        phone: '',
        name: '',
        email: '',
        id: '',
      }
      if (this.isNewCustomer === true) this.query = ''
    },

    // getAddressData(addressData, placeResultData, id) {
    //   this.daas.dispatch_object.pickup.address = placeResultData.formatted_address
    //   this.daas.dispatch_object.pickup.longitude = addressData.longitude
    //   this.daas.dispatch_object.pickup.latitude = addressData.latitude
    // },

    // getReceiverAddressData(addressData, placeResultData, id) {
    //   // parse from google search
    //   const c = id.split('map')[1]
    //   this.daas.dispatch_object.dropoffs[c].address = placeResultData.formatted_address
    //   this.daas.dispatch_object.dropoffs[c].longitude = addressData.longitude
    //   this.daas.dispatch_object.dropoffs[c].latitude = addressData.latitude
    // },

    // filterResults(data, text, field) {
    //   return data
    //     .filter(item => {
    //       if (item[field].toLowerCase().indexOf(text.toLowerCase()) > -1) {
    //         return item[field]
    //       }
    //       return false
    //     })
    //     .sort()
    // },

    repeatPackage(index) {
      this.daas.dispatch_object.dropoffs[index].packages.push({
        id: (this.daas.dispatch_object.dropoffs[index].nextPackageId += 1),
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.col[0].offsetHeight)
      })
    },

    removePackage(index, pindex) {
      this.daas.dispatch_object.dropoffs[index].packages.splice(pindex, 1)
      this.trTrimHeight(this.$refs.col[0].offsetHeight)
    },

    renderSuggestion(suggestion) {
      if (suggestion.name === 'hotels') {
        const image = suggestion.item
        return (
          <div class="d-flex">
            <div>
              <b-avatar src={image.thumbnailUrl} class="mr-50"></b-avatar>
            </div>
            <div>
              <span>{image.title}</span>
            </div>
          </div>
        )
      }
      return suggestion.item.name
    },

    getSuggestionValue(suggestion) {
      const { name, item } = suggestion
      return name === 'hotels' ? item.title : item.name
    },

    fetchResults() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        const customersPromise = axios.get(
          `${this.customersUrl}?param=${this.query}`,
        )

        Promise.all([customersPromise]).then(values => {
          this.suggestions = []
          this.selected = null
          this.resetCustomer()

          const customers = values[0].data
          //   customers.length
          //     && this.suggestions.push({ name: 'customers', data: customers })
          this.suggestions.push({ name: 'customers', data: customers })
        })
      }, this.debounceMilliseconds)
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/pages/page-misc.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
